import type DataItem from 'o365.modules.DataObject.Item.ts';
import type { IDataHandler } from 'o365.modules.DataObject.DataHandler.ts'
import type { DataObjectDefinitionFieldType, ViewDefinitionFieldType } from 'o365.modules.DataObject.Fields.ts'
//-----------------------------------------------
// Shared types between core DataObject classes
//-----------------------------------------------

/**
 * DataObject constructor options
 */
export type DataObjectOptions<T extends ItemModel = ItemModel> = {
    /** Id of the DataObject */
    id: string,
    /** VIewname from which to select */
    viewName: string,
    /**App id to which this DataObject belongs to */
    appId?: string,
    /**
     * Unique table name used when saving.
     * @default undefined
     */
    uniqueTable?: string,
    /**
     * Allow updates on this DataObject.
     * @default false
     */
    allowUpdate?: boolean,
    /**
     * Allow inserting new rows on this DataObject.
     * @default false
     */
    allowInsert?: boolean,
    /**
     * Allow deleting rows from this DataObject.
     * @default false
     */
    allowDelete?: boolean,
    /**
     * Show confirmation prompt before deleting a row.
     * @default false
     */
    deleteConfirm?: boolean,
    /**
     * Push new records instead of unshifting them.
     * @default false
     */
    createNewAtTheEnd?: boolean,
    /**
     * Use already retrieved data when filtering instead of fetching from database
     * @default false
     */
    clientSideFiltering?: boolean
    /**
     * Skip saving tries when current index is changed.
     * @default false
     */
    disableSaveOncurrentIndexChange?: boolean,
    /**
     * Select the first row when data is loaded.
     * @default false
     */
    selectFirstRowOnLoad?: boolean,
    /**
     * List of select fields used by this DataObject.
     * @default undefined
     */
    fields: DataObjectDefinitionFieldType[],
    /**
     * View definition object used to set up DataObject.Fields.
     * @default undefined
     */
    viewDefinition?: ViewDefinitionFieldType[],

    /**
     * Unique table definition oobject used to set up DataObject.Fields.
     * @default undefined
     */
    uniqueTableDefinition?: ViewDefinitionFieldType[],
    /**
     * Disable layout module.
     * @default false
     */
    disableLayouts?: boolean,
    /**
     * Initial filter string.
     * @default undefined
     */
    filterString?: string,
    /**
     * Initial where clause.
     * @default undefined
     */
    whereClause?: string,
    /**
     * Select distinct rows.
     * @default false
     */
    distinctRows?: boolean,
    /**
     * Max records used in retrieve requests.
     * @default -1
     */
    maxRecords?: number,
    /**
     * Load recents for lookups. Default is false here, 
     * but it can be enabled by the lookup component itself. 
     * @default false
     */
    loadRecents?: boolean,
    /**
     * Use dynamic loading (legacy paging | virtual list paging ). Default 
     * is false, but it can be enabled by the grid component.
     * @default false
     */
    dynamicLoading?: boolean,
    /**
     * Don't allow updates if Updated value is diffrent from currently loaded one
     * @default false
     */
    optimisticLocking?: boolean,
    /** 
     * Optional custom DataHandler class, must implement IDataHandler interface.
     * @default DataHandler
     */
    dataHandler?: IDataHandler<T>;
    /** Id of the master DataObject if this DataObject has MasterDetails defined */
    masterDataObject_ID?: string;
    /** MasterDetails bindings */
    masterDetailDefinition?: MasterDetailsDefinition[];
    /** Don't auto load this details object when master index changes */
    disableAutoLoad?: boolean;
    /** Don't allow to load master details with null value bindings */
    disableMasterDetailsNullValues?: boolean;
    /** When set to true will check fop properties configuration in the database and attempt to initialize properties data extension */
    enableProperties?: boolean;
    /** When true will stop attempting to save changes when load is called */
    disableSaveOnBeforeLoad?: boolean;
    /** When true will do additinal async calls for retrieving localized captions upon creating fields for views not found in the view definition */
    localizeFields?: boolean
    /**
     * When true will add context filtering in the backend.
     */
    expandView?: boolean,
    /**
     * Optional name of procedure that retruns a view definitino string for this dataobject.
     * Used for performance tuning
     */
    definitionProc?: string;
    definitionProcParameters?: Record<string, any>;
    /**
     * Optional parameters that will be provided to the select statement in retrieve/rowcount requests.
     */
    sqlStatementParameters?: Record<string, any>;
    isStaticId?: boolean;
};

/** Additional meta information for DataObjects */
export type DataObjectMetaOptions = {
    /** Indicates that the DataObject comes from appdesigner */
    isFromDesigner?: boolean;
};

/** Item model type */
export type ItemModel = {
    [key: string]: any
};
/** DataItem exteneded with an ItemModel type */
export type DataItemModel<T extends ItemModel = ItemModel> = T & DataItem<T>;
/** Options for operations on the record source */
export type RecordSourceOptions = {
    dataSourceId?: string,
    viewName?: string,
    uniqueTable?: string,
    distinctRows?: boolean,
    skip?: number,
    fields?: RecordSourceFieldType[],
    loadRecents?: boolean,
    maxRecords?: number,
    whereClause?: string,
    masterDetailString?: string,
    filterString?: string,
    optimisticLocking?: boolean,
    /**
     * For retrieve/rowcount operations this is used as definition procedure parameters  
     * For create/update operations these are item values
     */
    values?: Record<string, any>,
    searchString?: string, 
    searchFunction?: string,
    /** Build context filter query in the backend */
    expandView?: boolean;
    definitionProc?: string;
    /** Current user context id. Used together with expandView  */
    contextId?: number | null;
    /** Clear the storage before storing retrieved items */
    clearStorage?: boolean,
    /** Filter object for client side filtering (the filter expression) */
    filterObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Master details filter object for client side filtering */
    masterDetailObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Where clause filter object for client side filtering */
    whereObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Skip abort handler for this request */
    skipAbortCheck?: boolean;
    /** Optional additional where clause used when filtering properties */
    propertiesWhereClause?: string;
    definitionProcParameters?: Record<string, any>;
    /**
     * Optional parameters that will be provided to the select statement in retrieve/rowcount requests.
     */
    sqlStatementParameters?: Record<string, any>
    /**
     * Optional parameters for timezone name
     */
    timezoneName?: string


};

/** Options for cancelable events */
export type RecordSourceCancelableEvent = {
    /** When set to true will stop  further execution of the operation */
    cancelEvent?: boolean;
    /** When true will not reset the row after the event was canceled */
    skipRowReset?: boolean;
    /**
     * Promise that is resolved after all of the listener handlers have been executed.
     * Not all events will add this promise.
     */
    eventPromise?: Promise<void>
};

/** Definition between master and details fields */
export type MasterDetailsDefinition = {
    masterField: string,
    detailField: string,
    operator: MasterDetailsOperator
};

/** Possible master details binding operators */
export type MasterDetailsOperator = 'equals' | 'contains' | 'beginswith' | 'endswith';

/** Type for context filters */
export type ContextFilterType = {
    idPathField: string
} | (() => string)
    | ((contextIdPath: string) => string)
    | ((contextIdPath: string, contextId: number) => string);

/** Field options used for record source operations */
export type RecordSourceFieldType = {
    name: string;
    alias?: string;
    sortOrder?: number;
    sortDirection?: 'asc' | 'desc';
    groupByOrder?: number;
    groupByAggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG';
    aggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG';
    round?: number;
};

/** Optional options for overriding deleteConfrim options on the DataObject */
export type DataObjectDeleteConfirmOptions = {
    message?: string,
    softDeleteMessage?: string,
    title?: string,
    btnTextOk?: string,
    btnTextCancel?: string
    btnClassOk?: string,
    btnClassCancel?: string,
};